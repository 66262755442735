import './src/styles/global.css'

export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    `アプリケーションが更新されました。 ` + `最新版を表示しますか？`
  )
  if (answer === true) {
    window.location.reload()
  }
}
